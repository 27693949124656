export const PRIMARY_COLOR = "#0b3f63";
export const SITE_URL = "https://realestate.parecsa.com";
export const API_URL = "https://prserver.a3o.co/app/";
export const IMG_PATH = "https://realestate.parecsa.com/listingImages";
export const SITE_KEY = "6Le5HdUpAAAAALwtaRxsCV5hKtYw8I2lfnYSXwzZ"; //"6LfOndQpAAAAAAB3lze-4DR_yRw7MIK3YJuGWiuT";
export const LAYOUT_WIDTH = 1200;
export const VERSION = "0.1.28";
export const GOOGLE_MAPS_API_KEY_OLD =
   "AIzaSyADoic7_xRIBiWAFJRjlCueQO5gTCZVuIw";
export const GOOGLE_MAPS_API_KEY = "AIzaSyAn-WIplblcvFbdAQXxem9SSLcQ9MM1mTM";
export const MAPS_CENTER_COUNTRY_LONGITUDE = -80.93695;
export const MAPS_CENTER_COUNTRYLATITUDE = 8.40407;
export const MAPS_CENTER_CANAL_LONGITUDE = -79.5342;
export const MAPS_CENTER_CANAL_LATITUDE = 9.0;

export const MAPS_ZOOM = 10;
